import React, { useEffect, useMemo, useState } from "react";
import { PaymentForm, CreditCard } from "react-square-web-payments-sdk";
import Loader from "../Loader/Loader";
import "./SquarePaymentForm.scss";
import { SquareKey } from "../../apis/Api";
import { useDispatch } from "react-redux";
import { getCartItems } from "../../redux/addToCartSlice";
import { getLocalStorageItem } from "../../apis/locastorageHandle";

const SquarePaymentForm = ({ onPaymentCompleted }) => {
  const [squareAppId, setSquareAppId] = useState("");
  const [squareLocationId, setSquareLocationId] = useState("");
  const token = getLocalStorageItem("userToken");
  const dispatch = useDispatch();
  const headers = useMemo(
    () => ({
      "Content-Type": "application/json",
      "x-access-token": token,
    }),
    [token]
  );

  const handlePayment = async (token, verificationToken) => {
    try {
      const body = JSON.stringify({
        locationId: squareLocationId,
        sourceId: token.token,
        verificationToken,
        idempotencyKey: window.crypto.randomUUID(),
      });

      const response = await fetch(
        `https://node.nucleargauges.com:5000/api/v1/payment/square/create-payment`,
        {
          method: "POST",
          headers,
          body,
        }
      );

      if (response.ok) {
        const paymentData = await response.json();
        onPaymentCompleted(paymentData);
        dispatch(getCartItems());
      } else {
        console.error("Payment failed:", await response.text());
        // Handle payment failure
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      // Handle errors during the payment process
    }
  };

  // Fetch Square keys
  useEffect(() => {
    const fetchSquareKeys = async () => {
      try {
        const response = await SquareKey(headers);
        const { squareAppId, squareLocationId } = response.data.data;
        setSquareAppId(squareAppId);
        setSquareLocationId(squareLocationId);
      } catch (error) {
        console.error("Error fetching Square keys:", error);
      }
    };

    fetchSquareKeys();
  }, [headers]);

  if (!squareAppId || !squareLocationId) {
    return (
      <div className="paymentLoader">
        <Loader />
        <p>Loading payment form...</p>
      </div>
    );
  }

  return (
    <div className="square-payment-form">
      <PaymentForm
        applicationId={squareAppId}
        locationId={squareLocationId}
        cardTokenizeResponseReceived={async (token, verifiedBuyer) => {
          await handlePayment(token, verifiedBuyer);
        }}
      >
        <CreditCard
          buttonProps={{
            css: {
              backgroundColor: "#771520",
              fontSize: "14px",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#530f16",
              },
            },
          }}
        />
      </PaymentForm>
    </div>
  );
};

export default SquarePaymentForm;
