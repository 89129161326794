import React, { useState, useEffect } from "react";
import { Container, Form, Button, Row, Col, Spinner } from "react-bootstrap"; // Import Spinner from react-bootstrap
import { FaCheck } from "react-icons/fa";
import "./Test.scss";
import CustomButton from "../CustomButton/CustomButton";
import { Spin } from "antd";

const Test = () => {
  const [answers, setAnswers] = useState({
    q1: "",
    q2: "",
    q3: "",
    q4: "",
  });

  const [timeLeft, setTimeLeft] = useState(120);
  const [loading, setLoading] = useState(false); // State for loading spinner

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timerId);
    }
  }, [timeLeft]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAnswers({
      ...answers,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    // Simulate an API call or processing time
    setTimeout(() => {
      setLoading(false); // Stop loading after submission
    }, 2000); // Simulate a 2-second delay
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <Container className="test-screen-container">
      <div className="header">
        <h2>Test - Uranium Extraction Industrial Course</h2>
        <div className="timer">Timer: {formatTime(timeLeft)}</div>
      </div>
      <Spin spinning={loading}>
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} className="mb-3">
            <Form.Label as="legend" column sm={12}>
              Non-ionizing radiation is harmful to human cells
            </Form.Label>
            <Col sm={10}>
              <div className="custom-radio">
                <input
                  type="radio"
                  id="q1-true"
                  name="q1"
                  value="True"
                  onChange={handleChange}
                />
                <label htmlFor="q1-true">True</label>
              </div>
              <div className="custom-radio">
                <input
                  type="radio"
                  id="q1-false"
                  name="q1"
                  value="False"
                  onChange={handleChange}
                />
                <label htmlFor="q1-false">False</label>
              </div>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label as="legend" column sm={12}>
              Radiation is energy emitted by an?
            </Form.Label>
            <Col sm={4}>
              <div className="custom-radio">
                <input
                  type="radio"
                  id="q2-atom"
                  name="q2"
                  value="Atom"
                  onChange={handleChange}
                />
                <label htmlFor="q2-atom">Atom</label>
              </div>
              <div className="custom-radio">
                <input
                  type="radio"
                  id="q2-electrons"
                  name="q2"
                  value="Electrons"
                  onChange={handleChange}
                />
                <label htmlFor="q2-electrons">Electrons</label>
              </div>
            </Col>
            <Col>
              <div className="custom-radio">
                <input
                  type="radio"
                  id="q2-both"
                  name="q2"
                  value="Both"
                  onChange={handleChange}
                />
                <label htmlFor="q2-both">Both</label>
              </div>
              <div className="custom-radio">
                <input
                  type="radio"
                  id="q2-none"
                  name="q2"
                  value="None"
                  onChange={handleChange}
                />
                <label htmlFor="q2-none">None of the above</label>
              </div>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label as="legend" column sm={12}>
              Non-ionizing radiation does not have enough energy to remove
              electrons from surrounding atoms
            </Form.Label>
            <Col sm={10}>
              <div className="custom-radio">
                <input
                  type="radio"
                  id="q3-true"
                  name="q3"
                  value="True"
                  onChange={handleChange}
                />
                <label htmlFor="q3-true">True</label>
              </div>
              <div className="custom-radio">
                <input
                  type="radio"
                  id="q3-false"
                  name="q3"
                  value="False"
                  onChange={handleChange}
                />
                <label htmlFor="q3-false">False</label>
              </div>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label as="legend" column sm={12}>
              Ionizing radiation can cause cancer by?
            </Form.Label>
            <Col sm={4}>
              <div className="custom-radio">
                <input
                  type="radio"
                  id="q4-dna"
                  name="q4"
                  value="damaging DNA"
                  onChange={handleChange}
                />
                <label htmlFor="q4-dna">damaging DNA</label>
              </div>
              <div className="custom-radio">
                <input
                  type="radio"
                  id="q4-brain"
                  name="q4"
                  value="damaging Brain"
                  onChange={handleChange}
                />
                <label htmlFor="q4-brain">damaging Brain</label>
              </div>
            </Col>
            <Col>
              <div className="custom-radio">
                <input
                  type="radio"
                  id="q4-none"
                  name="q4"
                  value="It Doesn't affects health"
                  onChange={handleChange}
                />
                <label htmlFor="q4-none">It Doesn't affects health</label>
              </div>

              <div className="custom-radio">
                <input
                  type="radio"
                  id="q4-skin"
                  name="q4"
                  value="damaging skin"
                  onChange={handleChange}
                />
                <label htmlFor="q4-skin">damaging skin</label>
              </div>
            </Col>
          </Form.Group>

          <CustomButton
            text="Submit"
            bgColor="white"
            color="#3D3C6E"
            border="2px solid #3D3C6E"
          />
        </Form>
      </Spin>
    </Container>
  );
};

export default Test;
