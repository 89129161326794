import React, { useEffect, useMemo, useState } from "react";
import { GetAllCertificates } from "../../apis/Api";
import "./CertificateList.scss";
import { Link } from "react-router-dom";
const CertificateList = () => {
  const [certificte, setCertificate] = useState([]);
  useEffect(() => {
    const type = "course";
    GetAllCertificates(type)
      .then((res) => {
        setCertificate(res?.data?.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);
  return (
    <div className="certificateList">
      {certificte?.map((cert) => (
        <Link
          to={{
            pathname: "/showcertificate",
          }}
          state={{ certificateData: cert }}
          className="link"
          key={cert.id}
        >
          <div className="certificateContent">
            <h5>{cert?.dashboard_course?.name}</h5>
          </div>
        </Link>
      ))}
    </div>
  );
};
export default CertificateList;
