import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, Radio, message, Spin } from "antd";
import { useNavigate } from "react-router";
import loginImage from "../../assets/LoginImg.png";
import Logo from "../../assets/Logo.png";
import { MdOutlineEmail } from "react-icons/md";
import { SlLockOpen } from "react-icons/sl";
import "./Login.scss";
import { LoginAPI } from "../../apis/Api";
import CustomButton from "../CustomButton/CustomButton";
const Login = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [selectedRole, setSelectedRole] = useState("User");
  const [roleID, setRoleID] = useState("6");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const onFinish = (values) => {
    setLoading(true);
    // Define custom headers
    const headers = {
      role_id: roleID,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Custom timezone header
    };
    const formData = {
      email: values.email,
      password: values.password,
    };
    // Call the Login API
    LoginAPI(formData, headers)
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          message.success(res.data.message);
          localStorage.removeItem("myValue");
          localStorage.setItem("myValue", res?.data?.data?.role_id);
          let Token = res?.data?.data?.tokens?.access?.token;
          if (Token) {
            localStorage.removeItem("userToken");
            localStorage.setItem("userToken", Token);
            navigate("/courses");
          }
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log("error", e?.response?.data);
        if (
          e?.response?.data?.status === 400 &&
          e?.response?.data?.message ===
            "User is not verified yet. Please verify your OTP first."
        ) {
          navigate("/verifyotp", { state: formData.email });
        }
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onChange = (e) => {
    setSelectedRole(e.target.value);
    setRoleID(e.target.value === "User" ? "6" : "7");
    // localStorage.setItem("myValue", e.target.value === "User" ? "6" : "7");
  };
  return (
    <div className="login">
      <Row gutter={[16, 16]} className="row">
        {!isMobile && (
          <Col span={12}>
            <div className="leftSide">
              <img src={loginImage} alt="login" className="loginImg" />
              <div className="imageOverlay"></div>
            </div>
          </Col>
        )}
        <Col span={isMobile ? 24 : 12} className="column2">
          <div className="rightSide">
            <img
              src={Logo}
              width={200}
              alt="logo"
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
            />
            <h1 className="heading">Login</h1>
            <p className="para">Login with your email address & password</p>
            <div>
              <Radio.Group
                onChange={onChange}
                value={selectedRole}
                className="Radio_btn"
              >
                <Radio value="Corporate" className="input_custom">
                  Corporate
                </Radio>
                <Radio value="User" className="input_custom">
                  User
                </Radio>
              </Radio.Group>
            </div>
            <div className="form">
              <Spin spinning={loading}>
                <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  layout="vertical"
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your valid email!",
                        type: "email",
                      },
                    ]}
                  >
                    <Input
                      className="login_input"
                      placeholder="Email"
                      prefix={<MdOutlineEmail />}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password
                      className="login_input"
                      placeholder="Password"
                      prefix={<SlLockOpen />}
                    />
                  </Form.Item>
                  <p
                    className="forgot_pass"
                    onClick={() => navigate("/forgotpassword")}
                  >
                    Forgot Password?
                  </p>
                  <CustomButton
                    htmlType="submit"
                    text="Sign in"
                    bgColor="#3D3C6E"
                    width="100%"
                  />
                </Form>
              </Spin>
            </div>
          </div>
          {roleID !== "7" && (
            <div className="bottom_text">
              Don’t have an account?{" "}
              <span
                onClick={() => navigate("/signup")}
                style={{ cursor: "pointer", color: "#3D3C6E" }}
              >
                Sign Up
              </span>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};
export default Login;
