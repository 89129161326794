import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetReviewandRatingsApi } from "../apis/Api";

export const getReviewAndRatings = createAsyncThunk(
  "review/getReviewAndRatings",
  async ({ formData }, { rejectWithValue }) => {
    try {
      const response = await GetReviewandRatingsApi(formData);
      return response.data.data;
    } catch (error) {
      console.log(error)
      return rejectWithValue(error.response.data);
    }
  }
);

const reviewRatingSlice = createSlice({
  name: "review",
  initialState: {
    reviews: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReviewAndRatings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getReviewAndRatings.fulfilled, (state, action) => {
        state.loading = false;
        state.reviews = action.payload;
      })
      .addCase(getReviewAndRatings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export default reviewRatingSlice.reducer;
