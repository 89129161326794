import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, message, Spin } from "antd";
import { useNavigate, useLocation } from "react-router";
import { MdOutlineEmail } from "react-icons/md";
import SignUpImg from "../../assets/SignupImg.png";
import Logo from "../../assets/Logo.png";
import { sendOtpAPi, VerifyOTPAPI } from "../../apis/Api";
import CustomButton from "../CustomButton/CustomButton";
import "./VerifyOTP.scss";

const VerifyOTP = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false); // State for spinner
  const [loadingResendOtp, setLoadingResendOtp] = useState(false); // State for spinner

  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const email = location?.state;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const headers = {
    role_id: 6,
  };
  const onFinish = (values) => {
    setLoading(true); // Start loading spinner
    const formData = {
      email: email,
      otp: values.otp,
      type: "email_varification",
    };

    VerifyOTPAPI(formData, headers)
      .then((res) => {
        setLoading(false); // Stop loading spinner
        if (res.data.success) {
          message.success(res.data.message);
          navigate("/login");
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log("error", e);
        setLoading(false); // Stop loading spinner on error
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleSendOtp = async () => {
    setLoadingResendOtp(true); // Start loading spinner
    const formData = {
      email: email,
      type: "email_varification",
    };
    sendOtpAPi(formData, headers)
      .then((res) => {
        message.success("OTP sent to your email!");
        setLoadingResendOtp(false); // Stop loading spinner
      })
      .catch((e) => {
        console.log(e, "error");
        setLoading(false);
        setLoadingResendOtp(false); // Stop loading spinner
        // Stop loading spinner on error
      });
  };
  return (
    <div className="verify-otp">
      <Row gutter={[16, 16]} className="row">
        {!isMobile && (
          <Col span={12}>
            <div className="leftSide">
              <img src={SignUpImg} alt="login" className="loginImg" />
            </div>
          </Col>
        )}

        <Col span={isMobile ? 24 : 12} className="column2">
          <div className="rightSide">
            <img
              src={Logo}
              width={200}
              alt="logo"
              onClick={() => navigate("/")}
            />

            <h2 className="heading">Verify OTP</h2>
            <div className="form-container">
              <div className="form">
                <Spin spinning={loading}>
                  <Form
                    form={form}
                    name="verify_otp"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout="vertical"
                  >
                    <Form.Item label="Email" name="email" initialValue={email}>
                      <Input
                        className="input_custom"
                        prefix={<MdOutlineEmail />}
                        disabled
                      />
                    </Form.Item>
                    <Form.Item
                      label="OTP"
                      name="otp"
                      rules={[
                        { required: true, message: "Please input your OTP!" },
                      ]}
                    >
                      <Input
                        maxLength={4}
                        className="input_custom"
                        placeholder="Enter OTP"
                      />
                    </Form.Item>
                    <Spin spinning={loadingResendOtp}>
                      <p className="resend_otp" onClick={handleSendOtp}>
                        Resend Otp
                      </p>
                    </Spin>
                    <CustomButton
                      htmlType="submit"
                      text="Verify OTP"
                      bgColor="#3D3C6E"
                      width="100%"
                      // loading={loading} // Spinner loading state
                    />
                  </Form>
                </Spin>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default VerifyOTP;
