import React from "react";
import { Modal, Table } from "antd";
import CustomButton from "../CustomButton/CustomButton";
import "./CourseOngoing.scss";

const Result = ({
  isVisible,
  onClose,
  quizResults,
  restartQuiz,
  handleContinue,
}) => {
  const columns = [
    {
      title: "Total Questions",
      dataIndex: "Total Questions",
      key: "totalQuestions",
    },
    {
      title: "Total Score",
      dataIndex: "Total Score",
      key: "totalScore",
    },
    {
      title: "Correct Answers",
      dataIndex: "Correct Answers",
      key: "correctAnswers",
    },
    {
      title: "Wrong Answers",
      dataIndex: "Wrong Answers",
      key: "wrongAnswers",
    },
    {
      title: "User Score",
      dataIndex: "User Score",
      key: "userScore",
    },
  ];

  const dataSource = quizResults.map((result, index) => ({
    key: index,
    ...result,
  }));
  return (
    <Modal
      title="Scoreboard"
      visible={isVisible}
      // onCancel={onClose}
      maskClosable={false} // Disable closing by clicking outside
      keyboard={false}
      style={{ width: "700px" }}
      footer={[
        <div className="quizResultContainer">
          <CustomButton
            key="restart"
            text="Restart Quiz"
            onClick={restartQuiz}
          />

          <span style={{ margin: "0 8px" }} />
          <CustomButton key="done" text="Done" onClick={handleContinue} />
        </div>,
      ]}
    >
      <h5 style={{textAlign:"center",margin:"20px"}}>
        Your Score :
        {quizResults[0]?.["User Score"]}/{quizResults[0]?.["Total Score"]}
      </h5>

      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered
      />
    </Modal>
  );
};

export default Result;
