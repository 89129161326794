
import React, { useState } from "react";
import { Row, Col, Form, Input, message, Button, Spin } from "antd";
import { Container } from "react-bootstrap";
import { ContactUsAPI } from "../../apis/Api";
import "./ContactUs.scss";

const ContactUs = () => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true); // Manage button disabled state
  const [loading, setLoading] = useState(false); // Manage loading spinner state

  const onFinish = (values) => {

    const formData = {
      name: values.fullname,
      email: values.email,
      contact_number: values.phone,
      help_topic: values.help,
      enquiry_details: values.inquiry,
    };

    setLoading(true); // Set loading to true when submitting

    ContactUsAPI(formData)
      .then((res) => {
        if (res.data.success) {
          message.success("Data Submitted Successfully ");
          form.resetFields();
          setIsSubmitDisabled(true); // Reset button to disabled after submission
        } else {
          message.error(res.data.message);
        }
      })
      .catch(() => {
        message.error("There was an error submitting your inquiry.");
      })
      .finally(() => {
        setLoading(false); // Stop loading spinner after request completes
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // Handle form value changes to enable or disable the submit button
  const onValuesChange = (changedValues, allValues) => {
    const isAnyFieldFilled = Object.values(allValues).some((val) => val);
    setIsSubmitDisabled(!isAnyFieldFilled); // Enable button if any field is filled
  };

  return (
    <div className="contact-us">
      <div className="contactBanner">
        <Container>
          <h1 className="heading">Contact Us</h1>
          <p className="text">
            A better training experience. Designed by gauge operators and
            regulatory licencing specialists, for gauge operators and RSOs.
          </p>
        </Container>
      </div>
      <Container>
        <Row>
          <Col span={12} className="left-side">
            <h4>How can we help you?</h4>
            <p>
              New to Nuclear Gauging? Need a quick refresher? Our infographic &
              fast overview help with regulatory compliance, licensing,
              training, and more.
            </p>
          </Col>
          <Col span={12} className="right-side">
            <Spin spinning={loading}>
              <Form
                form={form}
                name="contact"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                onValuesChange={onValuesChange} // Track form changes
                layout="vertical"
              >
                <Form.Item
                  label="Full Name"
                  name="fullname"
                  rules={[
                    { required: true, message: "Please input your full name!" },
                  ]}
                >
                  <Input placeholder="Full Name" />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email!" },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  label="Phone Number"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <Input placeholder="Phone Number" />
                </Form.Item>
                <Form.Item
                  label="What is your inquiry about?"
                  name="inquiry"
                  rules={[
                    { required: true, message: "Please input your inquiry!" },
                  ]}
                >
                  <Input placeholder="What is your inquiry about?" />
                </Form.Item>
                <Form.Item
                  label="How can we help you?"
                  name="help"
                  rules={[
                    {
                      required: true,
                      message: "Please input how we can help you!",
                    },
                  ]}
                >
                  <TextArea rows={4} placeholder="How can we help you?" />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="submit-btn"
                    disabled={isSubmitDisabled}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUs;
