import React from "react";
import { Navigate } from "react-router-dom";
import { getLocalStorageItem } from "../../apis/locastorageHandle";

const PrivateRoute = ({ element: Component }) => {
  const token = getLocalStorageItem("userToken");
  return token ? <Component /> : <Navigate to="/login" />;
};

export default PrivateRoute;
