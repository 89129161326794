import React from "react";
import "./ShowAttempts.scss";
import { Container } from "react-bootstrap";
import { Card, Radio, Space } from "antd";

const ShowAttemptsAnswers = ({ attempt }) => {
  return (
    <Container className="ShowAttemptsAnswers">
      <h5>{`Attempt ${attempt?.test_marks[0]?.attempt} - ${attempt?.name}`}</h5>
      <small>{`Score ${attempt?.test_marks[0]?.test_marks}/${attempt?.question_count}`}</small>
      <Card>
        {attempt?.test_questions?.map((question, index) => {
          const userAnswerId = question?.test_user_progress[0]?.user_answer_id;
          const rightAnswerId = question?.right_answer_id;

          return (
            <div key={index}>
              <h6>{question?.question}</h6>
              <Radio.Group value={userAnswerId}>
                <Space direction="vertical">
                  {question?.test_options?.map((option) => (
                    <Radio
                      key={option.id}
                      value={option.id}
                      style={{
                        color:
                          option.id === userAnswerId
                            ? option.id === rightAnswerId
                              ? "green" // Correct answer in green
                              : "red" // Incorrect answer in red
                            : "black", // Default color for other options
                      }}
                    >
                      {option.option}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
              <hr />
            </div>
          );
        })}
      </Card>
    </Container>
  );
};

export default ShowAttemptsAnswers;
