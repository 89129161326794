import React from "react";
import { Card, Button, Skeleton, Progress } from "antd";
import { IMAGE_URL } from "../../apis/Host";
import { Link, useNavigate } from "react-router-dom";
import nodataavailable from "../../assets/nodataavailable.jpg";
import "./VideoCard.scss";
import { getLocalStorageItem } from "../../apis/locastorageHandle";

const VideoCard = ({ cardData, loading, loadingItem, disableTestButton }) => {
  const navigate = useNavigate();
  const userType = getLocalStorageItem("myValue");

  if (loading) {
    return (
      <div className="cards">
        {[...Array(6)].map((_, index) => (
          <Skeleton active key={index} className="card-skeleton" />
        ))}
      </div>
    );
  }

  if (!Array.isArray(cardData) || cardData.length === 0) {
    return (
      <div className="no-data">
        <img src={nodataavailable} alt="No data available" />
        <p>No data available</p>
      </div>
    );
  }

  return (
    <div className="cards">
      {cardData.map((item, index) => (
        <div key={index}>
          <Card
            hoverable
            className="card"
            cover={
              <img
                src={`${IMAGE_URL}/${item.image}`}
                alt="video"
                crossOrigin="anonymous"
              />
            }
          >
            {item?.link}
            <div
              className="textlink"
            onClick={()=>navigate(`/${item?.link}`)}
            >
              <h6 className="title">{item.title}</h6>
              <div>
                {item.type === "test" ? (
                  <div>
                    <span>{item.questions} Questions | </span>
                    <span>{item.minutes}</span>
                  </div>
                ) : (
                  <>
                    <div className="details">
                      <span>{item.lectures} Lectures</span>
                      <span>⭐ {item.rating}</span>
                    </div>
                    {item.dashboard && (
                      <div className="courseProgress">
                        {/* <Progress percent={item.progress} showInfo={false} />
                        <p>{item.progress}% Completed</p> */}

                        <Progress
                          percent={item.progress}
                          showInfo={false}
                          strokeColor={
                            item.progress === 100 ? "green" : "orange"
                          }
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
              {item.price && <h6 className="price">{item.price}</h6>}
              {item.attempt && (
                <h6
                  className="price"
                  onClick={() => navigate(`/show-attempt/${item?.test_id}`)}
                  style={{ textDecoration: "underline", color: "blue" }}
                >
                  Attempts: {item.attempt}
                </h6>
              )}
            </div>

            <Button
              type="primary"
              className="button"
              loading={
                item.button_status === "Add To Cart" &&
                loadingItem === (item.course_id || item.test_id)
              }
              onClick={() => {
                if (item.is_purchased !== undefined && item.is_purchased) {
                  navigate(item.navigateLink);
                } else {
                  item.handleClick(
                    item.course_id || item.test_id,
                    item.type,
                    item.navigateLink
                  );
                }
              }}
              disabled={
                (disableTestButton &&
                  item.type === "test" &&
                  item.questions === 0) ||
                item.attempt === "0/3" ||
                (item.role_id === "7" &&
                  item.button_status === "Add To Cart") ||
                (userType === "7" && item.button_status === "Add To Cart")
              }
            >
              {item.button_status}
            </Button>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default VideoCard;
