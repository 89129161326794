import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./Components/Login/Login";
import SignUp from "./Components/SignUp/SignUp";
import ContactUs from "./Components/ContactUs/ContactUs";
import Courses from "./Components/Courses/Courses";
import ShoppingCart from "./Components/ShoppingCart/ShoppingCart";
import Profile from "./Components/Profile/Profile";
import Forum from "./Components/Forum/Forum";
import CourseOngoing from "./Components/CourseOngoing/CourseOngoing";
import CourseDetails from "./Components/CourseDetails/CourseDetails";
import Dashboard from "./Components/Dashboard/Dashboard";
import Test from "./Components/Test/Test";
import VerifyOTP from "./Components/VerifyOTP/VerifyOTP";
import PrivateRoute from "./Components/PrivateRoute/PrivateRoute";
import ForgotPassword from "./Components/ForgotPassword/ForgotPassword";
import Quiz from "./Components/Quiz/Quiz";
import ShowCertificate from "./Components/ShowCertificate/ShowCertificate";
import Landing from "./Components/Landing/Landing";
import ShowAttempts from "./Components/ShowAttempts/ShowAttempts";
import ShowAttemptsAnswers from "./Components/ShowAttempts/ShowAttemptsAnswers";

const WebRouter = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Landing />} />

      <Route exact path="/login" element={<Login />} />
      <Route exact path="/signup" element={<SignUp />} />
      <Route exact path="/forgotpassword" element={<ForgotPassword />} />
      <Route exact path="/verifyotp" element={<VerifyOTP />} />
      <Route exact path="/contactus" element={<ContactUs />} />
      <Route exact path="/courses" element={<Courses />} />
      <Route exact path="/show-attempt/:test_id" element={<ShowAttempts />} />
      {/* <Route exact path="/show-attempt/:test_id" element={<ShowAttemptsAnswers />} /> */}


      <Route
        exact
        path="/shopingcart"
        element={<PrivateRoute element={ShoppingCart} />}
      />
      <Route
        exact
        path="/profile"
        element={<PrivateRoute element={Profile} />}
      />
      <Route exact path="/forum" element={<PrivateRoute element={Forum} />} />
      <Route
        exact
        path="/courseongoing/:id"
        element={<PrivateRoute element={CourseOngoing} />}
      />
      <Route exact path="/quiz/:id" element={<PrivateRoute element={Quiz} />} />

      <Route
        exact
        path="/coursedetails/:id"
        element={<PrivateRoute element={CourseDetails} />}
      />
      <Route
        exact
        path="/dashboard"
        element={<PrivateRoute element={Dashboard} />}
      />
      <Route
        exact
        path="/showcertificate"
        element={<PrivateRoute element={ShowCertificate} />}
      />
      <Route exact path="/test" element={<PrivateRoute element={Test} />} />
    </Routes>
  );
};

export default WebRouter;
