import React, { useEffect, useState } from "react";
import "./ShowAttempts.scss";
import { Container } from "react-bootstrap";
import { Card, Modal, Skeleton } from "antd";
import { useParams } from "react-router";
import { GetTestAttempts, GetTestAttemptsAnswers } from "../../apis/Api";
import ShowAttemptsAnswers from "./ShowAttemptsAnswers";

const ShowAttempts = () => {
  const { test_id } = useParams();
  const [testAttempts, setTestAttempts] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedAttempt, setSelectedAttempt] = useState(null);
  const [loading, setLoading] = useState(true); // State for loading

  useEffect(() => {
    setLoading(true); // Set loading to true before fetching data
    GetTestAttempts(test_id)
      .then((res) => {
        setTestAttempts(res?.data?.data);
      })
      .catch((e) => {
        console.log(e, "error===>>");
      })
      .finally(() => setLoading(false)); // Set loading to false once data is fetched
  }, [test_id]);

  const handleClick = (attemptId) => {
    const formData = {
      attempt_id: attemptId,
    };
    GetTestAttemptsAnswers(test_id, formData)
      .then((res) => {
        setSelectedAttempt(res?.data?.data); // Set the selected attempt data here
        setIsModalVisible(true);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedAttempt(null);
  };

  return (
    <Container className="ShowAttempts">
      {loading ? (
        <>
          <Skeleton active paragraph={{ rows: 1 }} title={{ width: "60%" }} />
          <Skeleton active paragraph={{ rows: 1 }} title={{ width: "80%" }} />
          <Skeleton active paragraph={{ rows: 3 }} />
        </>
      ) : (
        <>
          <h5>{testAttempts[0]?.testName}</h5>
          <p>{testAttempts[0]?.totalQuestions} Questions | {testAttempts[0]?.testDuration} minutes</p>
          <h6>{testAttempts?.length} Attempts</h6>
          {testAttempts?.map((item, index) => (
            <Card
              className="score_card"
              key={index}
              onClick={() => handleClick(item?.attempt)}
            >
              <div>
                <div className="section-1">
                  <h6 className="score">
                    Score {item?.test_marks}/{item?.totalQuestions}
                  </h6>
                  <div className="failed_btn">{item?.status}</div>
                </div>
                <small>{new Date(item.attempt_date).toLocaleDateString()}</small>
              </div>
            </Card>
          ))}
        </>
      )}

      <Modal visible={isModalVisible} onCancel={handleCancel} footer={null}>
        {selectedAttempt && <ShowAttemptsAnswers attempt={selectedAttempt} />}
      </Modal>
    </Container>
  );
};

export default ShowAttempts;
