import React from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router";
import { FaArrowLeft } from "react-icons/fa"; // Import an icon for better UX
import "./TestResult.scss";

const TestResult = ({ quizData, resultData }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/dashboard");
  };

  const scorePercentage = Math.round(
    (resultData["User Score"] / resultData["Total Score"]) * 100
  );
  const feedbackMessage =
    scorePercentage >= 80
      ? "Excellent!"
      : scorePercentage >= 50
      ? "Good Job!"
      : "Keep Trying!";

  return (
    <div className="result-container">
      <Container>
        <div className="result-card">
          <h2>Quiz Result</h2>
          <p>
            <strong>Module:</strong> {quizData.module_number}
          </p>

          <div className="result-details">
            <p>
              <strong>Total Questions:</strong> {resultData["Total Questions"]}
            </p>
            <p>
              <strong>Correct Answers:</strong> {resultData["Correct Answers"]}
            </p>
            <p>
              <strong>Your Score:</strong> {resultData["User Score"]} /{" "}
              {resultData["Total Score"]}
            </p>
          </div>

          <div className="score-section">
            <h3 className="final-score">{scorePercentage}%</h3>
            <p className="feedback">{feedbackMessage}</p>
          </div>
          <div className="btn">
            <button className="back-btn" onClick={handleBack}>
              <FaArrowLeft style={{ marginRight: "0.5rem" }} />
              Back to Dashboard
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TestResult;
