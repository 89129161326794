import React, { useEffect, useState } from "react";
import { Form, Input, Row, Col, message, Select, Spin } from "antd";
import "./ProfileForm.scss";
import CustomButton from "../CustomButton/CustomButton";
import { EditProfile } from "../../apis/Api";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../redux/profileSlice";
import parsePhoneNumberFromString from "libphonenumber-js";
import PhoneInput from "react-phone-number-input";

const { Option } = Select;

const ProfileForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true); // State for button disabled
  const [isSubmitting, setIsSubmitting] = useState(false); // State for loading
  const { profile, loading } = useSelector((state) => state.profileSlice);

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  useEffect(() => {
    if (profile) {
      form.setFieldsValue({
        fullName: profile.user_profile.name,
        email: profile.email,
        phoneNumber: profile.user_profile.mobile,
        accountType: profile.account_type,
      });
    }
  }, [profile, form]);

  const onFinish = async (values) => {
    const formData = {
      name: values.fullName,
      mobile: values.phoneNumber,
      account_type: values.accountType,
    };
    try {
      setIsSubmitting(true); // Start loading
      await EditProfile(formData);
      message.success("Profile updated successfully");
      dispatch(getProfile());
    } catch (error) {
      console.error("Error updating profile:", error);
      message.error("Failed to update profile. Please try again.");
    } finally {
      setIsSubmitting(false); // Stop loading
    }
  };

  // This function checks if all fields are filled and enables/disables the submit button accordingly
  const onValuesChange = (changedValues, allValues) => {
    const allFieldsFilled = Object.values(allValues).every(
      (value) => value && value.toString().trim() !== ""
    );
    setIsSubmitDisabled(!allFieldsFilled);
  };

  const [phoneNumber, setPhoneNumber] = useState("");

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
  };
  const validatePhoneNumber = (rule, value) => {
    if (!value) {
      return Promise.reject("Phone number is required.");
    }
    const phoneNumber = parsePhoneNumberFromString(value);
    if (phoneNumber && phoneNumber.isValid()) {
      return Promise.resolve();
    }
    return Promise.reject("Invalid phone number.");
  };

  return (
    <>
      <Spin spinning={loading}>
        <Form
          form={form}
          name="profile_form"
          layout="vertical"
          onFinish={onFinish}
          onValuesChange={onValuesChange} // Track form changes to manage button state
          className="profile-form"
          initialValues={
            profile
              ? {
                  fullName: profile?.user_profile?.name,
                  email: profile?.email,
                  phoneNumber: profile?.user_profile?.mobile,
                  accountType: profile?.account_type,
                }
              : {}
          }
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="fullName"
                label="Full Name"
                rules={[
                  { required: true, message: "Please input your full name!" },
                  {
                    pattern: /^[a-zA-Z\s]*$/,
                    message: "Full name can only contain letters and spaces.",
                  },
                  {
                    min: 3,
                    message: "Full name must be at least 3 characters.",
                  },
                  {
                    max: 50,
                    message: "Full name can't exceed 50 characters.",
                  },
                ]}
              >
                <Input
                  placeholder="Full Name"
                  onBlur={(e) => {
                    const { value } = e.target;
                    // Capitalize the first letter of each word and make the rest lowercase
                    const formattedValue = value.replace(
                      /\b\w+/g,
                      (word) =>
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    );
                    // Set the formatted value back into the form
                    form.setFieldsValue({ fullName: formattedValue });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input placeholder="Email" disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              {/* <Form.Item
                name="phoneNumber"
                label="Phone Number"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                ]}
              >
                <Input placeholder="Phone Number" />
              </Form.Item> */}
                <Form.Item
                       name="phoneNumber"
                label="Phone Number"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                      {
                        validator: validatePhoneNumber,
                      },
                    ]}
                    >
                    <PhoneInput
                    className="Custom-phone-input-style"
                      placeholder="Enter Mobile Number"
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      defaultCountry="US"
                      countries={["US", "CA"]}
                      inputClass="custom-phone-input"
                     
                    />
                  </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item name="accountType" label="Account Type">
                <Select placeholder="Select Account Type">
                  {profile?.role_id === 6 ? (
                    <Option value="user">User</Option>
                  ) : (
                    <>
                      <Option value="user">User</Option>
                      <Option value="corporate">Corporate</Option>
                    </>
                  )}
                </Select>
              </Form.Item>
            </Col> */}
          </Row>
          <Form.Item>
            <CustomButton
              text={isSubmitting ? "Submitting..." : "Edit Profile"}
              bgColor="#3D3C6E"
              htmlType="submit"
              disabled={isSubmitDisabled || isSubmitting} // Disable when submitting
            />
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};

export default ProfileForm;
