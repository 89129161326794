import apiClient from "./apiClient";
// SignUp API
export const SignUpAPI = async (formData) => {
  return await apiClient.post(`user/auth/register`, formData);
};
// Verify OTP API
export const VerifyOTPAPI = async (formData) => {
  return await apiClient.post(`user/auth/verify-otp`, formData);
};
// Login API
export const LoginAPI = async (formData, headers) => {
  return await apiClient.post(`user/auth/login`, formData, { headers });
};
// Contact Us API
export const ContactUsAPI = async (formData) => {
  return await apiClient.post(`contactUs/createContactUs`, formData);
};
// Get All Courses
export const GetAllCoursesAPI = async () => {
  return await apiClient.get(`course/getAllCourse`);
};
// Get All Course By Token
export const GetAllCoursesByToken = async () => {
  return await apiClient.get(`course/getAllCourseByToken`);
};
// Get All Test By Token
export const GetAllTestByToken = async () => {
  return await apiClient.get(`test/getAllTestByToken`);
};
// Get Course By ID
export const GetCourseById = async (id) => {
  return await apiClient.get(`course/findCourseById/${id}`);
};
// Get All Tests
export const GetAllTestsAPI = async () => {
  return await apiClient.get(`test/getAlltest`);
};
// Post FAQ
export const PostFAQApi = async (question) => {
  return await apiClient.post(`/forum/createQuestion`, question);
};
// Get FAQ
export const GetFAQAPI = async () => {
  return await apiClient.get(`forum/getAllQuestions`);
};
// Post Review and Ratings
export const PostReviewandRatingsApi = async (formData) => {
  return await apiClient.post(`rating/postReviewAndRating`, formData);
};
// Get Review and Ratings
export const GetReviewandRatingsApi = async (formData) => {
  return await apiClient.post(`rating/getReviewsAndRatings`, formData);
};
// Edit Review and Rating
export const EditReviewandRating = async (formData) => {
  return await apiClient.post(`rating/updateReviewAndRating`, formData);
};
// Delete Review and Ratings
export const DeleteReviewandRating = async (id) => {
  return await apiClient.delete(`rating/deleteReviewAndRating/${id}`);
};
// Post Cart Items
export const PostAddToCart = async (formData) => {
  return await apiClient.post(`cart/addToCart`, formData);
};
// Get Cart Items
export const GetCartItems = async () => {
  return await apiClient.get(`cart/getAllItemsInCart`);
};
// Buy Cart Items
export const BuyCartItem = async () => {
  return await apiClient.post(`cart/buyItemsInCart`);
};
// Delete Cart Item
export const DeleteCartItems = async (formData) => {
  return await apiClient.post(`cart/deleteItemFromCart`, formData);
};
// Get Profile
export const GetProfile = async () => {
  return await apiClient.get(`user/profile`);
};
// Edit Profile
export const EditProfile = async (formData) => {
  return await apiClient.put(`user/updateUserProfile`, formData);
};
// Dashboard Items
export const DashBoardItems = async (type) => {
  return await apiClient.post(`cart/getAllItemsFromDashboard`, type);
};
// Get Purchase History
export const GetPurchaseHistory = async () => {
  return await apiClient.get(`cart/getPurchaseHistory`);
};
// Get Lectures List
export const GetLecturesList = async (id) => {
  return await apiClient.get(`course/getAllLecturesInCourse/${id}`);
};
// Get Course Progress By ID
export const GetProgress = async (id) => {
  return await apiClient.get(`course/getCourseProgress/${id}`);
};
// Get Find Lecture By ID
export const GetFindLecturesById = async (id) => {
  return await apiClient.get(`course/findLectureById/${id}`);
};
// Get Questions By Lecture ID
export const GetQuestionsByLectureId = async (id) => {
  return await apiClient.get(`quiz/getQuestionsByLectureId/${id}`);
};
// Post Answers By Lecture ID
export const PostAnswersByLectureId = async (formData) => {
  return await apiClient.post(`quiz/submitQuiz`, formData);
};
// Get Quiz Result By ID
export const GetQuizResultById = async (id) => {
  return await apiClient.get(`quiz/getAnswerSheet/${id}`);
};
// Forgot Password APIs
export const sendOtpAPi = async (formData) => {
  return await apiClient.post(`/user/auth/otp`, formData);
};
export const VerifyAPi = async (formData) => {
  return await apiClient.post(`/user/auth/verify-otp`, formData);
};
export const ForgotPasswordApi = async (formData) => {
  return await apiClient.post(`/user/auth/forgot-password`, formData);
};
// Get Company Name List
export const GetCompanyName = async () => {
  return await apiClient.get(`/corporate/getAllCompanyName`);
};
// Share Data
export const PostShareData = async (corporate_id) => {
  return await apiClient.post(`/corporate/shareData`, { corporate_id });
};
// Shared Data List
export const SharedDataList = async () => {
  return await apiClient.get(`corporate/getListOfSharedData`);
};
// Get Quiz By ID
export const GetQizeById = async (id) => {
  return await apiClient.get(`test/getQuestionsByTestId/${id}`);
};
// Lecture Mark As Complete
export const LectureMarkAsComplete = async (formData) => {
  return await apiClient.post(`user/course/markLectureAsCompleted`, formData);
};
// User Test Attempts
export const UserTestAttempts = async (id) => {
  return await apiClient.get(`/test/getAttemptsTestId/${id}`);
};
// Submit Test
export const SubmitTest = async (formData) => {
  return await apiClient.post(`test/submitTest`, formData);
};
// End Quiz
export const EndQuiz = async (formData) => {
  return await apiClient.post(`test/submitTest`, formData);
};
// Square Key
export const SquareKey = async () => {
  return await apiClient.get(`payment/square/square-key`);
};
// Create Payment
export const CreatePayment = async (formData) => {
  return await apiClient.post(`payment/square/create-payment`, formData);
};
// Get All Certificates
export const GetAllCertificates = async (type) => {
  return await apiClient.post(`cart/getAllCertificates`, { type });
};

export const DeleteShareData = async (formdata) => {
  return await apiClient.post(`corporate/removeSharedDataAccess`, formdata);
};

export const GetTestAttempts = async (test_id) => {
  return await apiClient.get(`test/getAttemptsMarksTestId/${test_id}`);
};

export const GetTestAttemptsAnswers = async (test_id, formData) => {

  return await apiClient.post(
    `test/getTestMarkSheetByAttemptId/${test_id}`,
    formData
  );
};
