import React, { useEffect, useState } from "react";
import { Table, message, Button, Modal, Space, Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import {
  DeleteShareData,
  GetCompanyName,
  PostShareData,
  SharedDataList,
} from "../../apis/Api";
import "./SharedData.scss";

const SharedData = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    hanldeShareList();
  }, []);
  const hanldeShareList = () => {
    setLoading(true);

    SharedDataList()
      .then((res) => {
        if (res.data.code === 200) {
          const formattedData = res.data.data.map((item) => ({
            ...item,
            ...item.corporate_shared_data,
            id: item.id,
          }));
          setData(formattedData);
        } else {
          message.error("Failed to fetch data");
        }
      })
      .catch((error) => {
        // console.log("error", error);
        message.error("An error occurred while fetching data");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    GetCompanyName()
      .then((res) => {
        if (res.data && res.data.data) {
          setCompanies(res.data.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const handleDelete = (id) => {
    const formData = {
      corporate_id: selectedRowKeys.length === 0 ? [id] : selectedRowKeys,
    };
    Modal.confirm({
      title: "Confirm",
      content: "Are you sure you want to delete this data?",
      onOk: () => {
        DeleteShareData(formData)
          .then((res) => {
            message.success("Data deleted successfully");
          hanldeShareList();

            setData(data.filter((item) => item.id !== id));
            setSelectedRowKeys(selectedRowKeys.filter((key) => key !== id));
          })
          .catch((error) => {
            console.log("error", error);
            message.error("Failed to delete data");
          });
      },
    });
  };

  // const handleBulkDelete = () => {
  //   const formData = {
  //     corporate_id: selectedRowKeys,
  //   };
  //   Modal.confirm({
  //     title: "Confirm",
  //     content: "Are you sure you want to delete the selected data?",
  //     onOk: () => {
  //       DeleteShareData(formData)
  //         .then(() => {
  //           message.success("Selected data deleted successfully");
  //           setData(data.filter((item) => !selectedRowKeys.includes(item.id)));
  //           setSelectedRowKeys([]);
  //         })
  //         .catch((error) => {
  //           console.log("error", error);
  //           message.error("Failed to delete selected data");
  //         });
  //     },
  //   });
  // };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            danger
            onClick={() => handleDelete(record.id)}
            disabled={selectedRowKeys.length > 1}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => setSelectedRowKeys(newSelectedRowKeys),
  };

  const handleOk = () => {
    if (selectedCompany) {
      PostShareData(selectedCompany.id)
        .then((res) => {
          message.success(`Data shared with ${selectedCompany.company_name}`);
          hanldeShareList();
        })
        .catch((error) => {
          console.log("error", error);
          message.error("Failed to share data");
        });
      setIsModalVisible(false);
    } else {
      message.error("No company selected");
    }
  };
  const handleMenuClick = (e) => {
    const selected = companies.find(
      (company) => company.id === parseInt(e.key)
    );
    setSelectedCompany(selected);
    setDropdownVisible(false);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const menu = (
    <Menu onClick={handleMenuClick}>
      {companies.map((company) => (
        <Menu.Item key={company.id}>{company.company_name}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div>
      <div className="page-header">
        <div>
          <h3>Shared Data</h3>
          <p>View and manage the shared data information below</p>
          <Button
            type="primary"
            danger
            onClick={handleDelete}
            disabled={selectedRowKeys.length === 0}
          >
            Delete Selected
          </Button>
        </div>
        <div>
          <Dropdown
            overlay={menu}
            visible={dropdownVisible}
            onVisibleChange={(flag) => setDropdownVisible(flag)}
          >
            <button
              className="shareData"
              onClick={() => setDropdownVisible(!dropdownVisible)}
            >
              Share Data <DownOutlined />
            </button>
          </Dropdown>
        </div>
      </div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey="id"
        pagination={{ pageSize: 10 }}
      />
      <Modal
        title="Share Data Confirmation"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
      >
        <p>
          Are you sure you want to share your data with{" "}
          {selectedCompany?.company_name}?
        </p>
      </Modal>
    </div>
  );
};

export default SharedData;
