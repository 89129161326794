import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FaStar } from "react-icons/fa";
import { Dropdown, Space, Rate, Modal, Input, message } from "antd";
import { HiDotsVertical } from "react-icons/hi";
import { BsPersonFill } from "react-icons/bs";
import "./Reviews.scss";
import { DeleteReviewandRating, EditReviewandRating } from "../../apis/Api";

const items = [
  { key: "1", label: "Edit" },
  { key: "2", label: "Delete" },
];

const Reviews = ({ reviews, loading, course_id,setIsRatingProvided }) => {
  const [reviewData, setReviewData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentReview, setCurrentReview] = useState({});
  const [newRating, setNewRating] = useState(0);
  const [newReview, setNewReview] = useState("");


  const showModal = (review) => {
    setCurrentReview(review);
    setNewRating(review.rating);
    setNewReview(review.review);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    const formData = {
      review: newReview,
      rating: newRating,
      course_id: course_id,
    };

    try {
      const response = await EditReviewandRating(formData);
      if (response.data.success) {
        message.success(response.data.message);
        setReviewData((prevReviews) =>
          prevReviews.map((rev) =>
            rev.id === currentReview.id
              ? { ...rev, rating: newRating, review: newReview }
              : rev
          )
        );
      } else {
        message.error("Failed to update review and rating.");
      }
    } catch (error) {
      console.error("error", error);
      message.error("An error occurred while updating the review.");
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDelete = async (id) => {
    try {
      const response = await DeleteReviewandRating(id);
      if (response.data.success) {
        message.success(response.data.message);
        setReviewData((prevReviews) =>
          prevReviews.filter((rev) => rev.id !== id),
        setIsRatingProvided(reviews?.data?.some((item) => item?.user_rating_provided))
        );
      } else {
        message.error("Failed to delete review and rating.");
      }
    } catch (error) {
      console.error("error", error);
      message.error("An error occurred while deleting the review.");
    }
  };

  useEffect(() => {
    if (reviews) {
      setReviewData(reviews?.data);
    }
  }, [reviews]);

  const totalRating = reviewData?.reduce(
    (acc, review) => acc + parseFloat(review.rating),
    0
  );
  const averageRating = reviewData?.length > 0 ? totalRating / reviewData?.length : 0;
  const numberOfReviews = reviewData?.length;

  if (loading) return <p>Loading...</p>;
 

  return (
    <Container className="reviews-container">
      <div className="views-all">
        <h2>Reviews</h2>
        <p>View All</p>
      </div>
      {reviewData?.length === 0 && (
        <Card className="no-reviews-card">
          <Card.Body>
            <p>No reviews available for this course.</p>
          </Card.Body>
        </Card>
      )}
      {reviewData?.map((review) => (
        <Card key={review.id} className="review-card">
          <Card.Body>
            <Row>
              <Col md={1} className="user-icon">
                <div className="user-circle">
                  {review.review_course.user_profile.name.charAt(0)}
                </div>
              </Col>
              <Col md={11}>
                <div className="review-header">
                  <div>
                    <div className="review-name">
                      {review.review_course.user_profile.name}
                    </div>
                    <div className="review-rating">
                      <FaStar />
                      <div className="rating-number">{review.rating}</div>
                    </div>
                    <div className="review-date">
                      {new Date(review.created_at).toLocaleDateString()}
                    </div>
                  </div>
                  {review.user_rating_provided && (
                    <Space direction="vertical">
                      <Space wrap>
                        <Dropdown
                          menu={{
                            items,
                            onClick: ({ key }) => {
                              if (key === "1") {
                                showModal(review);
                              } else if (key === "2") {
                                handleDelete(review.id);
                              }
                            },
                          }}
                          placement="bottom"
                        >
                          <HiDotsVertical size={25} />
                        </Dropdown>
                      </Space>
                    </Space>
                  )}
                </div>
                <Card.Text className="review-comment">
                  {review.review}
                </Card.Text>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ))}
      <Modal
        title="Edit Review"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div>
          <p className="labels">Rating</p>
          <Rate value={newRating} onChange={setNewRating} />
        </div>
        <div>
          <p className="labels">Review</p>
          <Input.TextArea
            value={newReview}
            onChange={(e) => setNewReview(e.target.value)}
          />
        </div>
      </Modal>
    </Container>
  );
};

export default Reviews;
